/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'grip-horizontal': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 8a1 1 0 110 2 1 1 0 010-2m0-3a1 1 0 110 2 1 1 0 010-2m3 3a1 1 0 110 2 1 1 0 010-2m0-3a1 1 0 110 2 1 1 0 010-2m3 3a1 1 0 110 2 1 1 0 010-2m0-3a1 1 0 110 2 1 1 0 010-2m3 3a1 1 0 110 2 1 1 0 010-2m0-3a1 1 0 110 2 1 1 0 010-2m3 3a1 1 0 110 2 1 1 0 010-2m0-3a1 1 0 110 2 1 1 0 010-2"/>',
    },
});
